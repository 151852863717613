<template>
  <div class="products main-container">
    <div class="product-item left">
      <div class="item-container">
        <div class="title">Engineering Consulting</div>
        <img
          class="products1"
          src="../../assets/product1.jpg"
          alt="Engineering Consulting"
          @mouseover="handlerLeftOnHover"
          @mouseout="handlerLeftOnHover"
        />

        <div class="detail-info" v-show="state.isLeftShowDiv">
          <div class="info-div">
            <div class="img-item">
              <span>Konstruktion</span>
              <img src="../../assets/konstruktion.png" alt="Konstruktion" />
            </div>
            <div class="img-item">
              <span>Automationsanlage</span>
              <img
                src="../../assets/automationsanlage.png"
                alt="Automationsanlage"
              />
            </div>
          </div>
          <div class="info-div">
            <div class="img-item" style="left: 100px;">
              <span>Simulation</span>
              <img src="../../assets/simulation.png" style="border-radius: 13px;" alt="Simulation" />
            </div>
             <div class="img-item-last" style="top: 35px;left: -17px;">
              <span>Energiespeicher</span>
              <img src="../../assets/energiespeicher.png" alt="Energiespeicher" />
            </div>
            <div class="img-item" style="right: 150px;">
              <span>IT Solution</span>
              <img src="../../assets/ITSolution.png" alt="IT Solution" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-item right">
      <div class="item-container">
        <div class="detail-info" v-show="state.isRightShowDiv">
          <span class="info-title">Für den Außenhandel</span>
          <span class="info-title">zwischen Deutschland und China</span>
          <span class="info-title">sind wir dabei.</span>
        </div>
        <img
          class="products2"
          src="../../assets/product2.jpg"
          alt="Import & Export"
          @mouseover="handlerRightOnHover"
          @mouseout="handlerRightOnHover"
        />
        <div class="title">Import & Export</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
export default {
  setup() {
    let state = reactive({
      isLeftShowDiv: false,
      isRightShowDiv: false,
    });

    function handlerLeftOnHover() {
      state.isLeftShowDiv = !state.isLeftShowDiv;
    }
    function handlerRightOnHover() {
      state.isRightShowDiv = !state.isRightShowDiv;
    }

    return { state, handlerLeftOnHover, handlerRightOnHover };
  },
};
</script>

<style scoped  lang="scss">
.page-body {
    background-image: linear-gradient(to right, #0e0e0e, #5b5757);
}
.products {
  .left {
    .detail-info {
      width: 820px;
      height: 460px;
      position: absolute;
      top: 85px;
      left: -110px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      .info-div {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
    .img-item,
    .img-item-last {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;

      span {
        color: #fff;
        margin-bottom: 10px;
      }
      img {
        width: 90px;
        height: 91px;
      }
    }
    .title {
      margin-bottom: 30px;
    }
  }
  .right {
    justify-content: flex-end;

    .detail-info {
      width: 400px;
      height: 130px;
      position: absolute;
      top: -90px;
      text-align: center;
      color: #f4b183;
      font-size: 20px;
      font-weight: bold;

      .info-title {
        display: block;
      }
    }

    .title {
      margin-top: 30px;
    }
  }
  .product-item {
    height: 200px;
    display: flex;
    align-items: center;
    .item-container {
      width: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;

      .title {
        font-size: 30px;
        display: inline-block;
        width: 60%;
        text-align: center;
      }

      .products1,
      .products2 {
        display: inline-block;
        position: relative;
        width: 200px;
        border-radius: 50%;
        cursor: pointer;
        /* box-shadow: 5px 120px 85px rgba(0, 0, 0, 0.7); */
        /*考虑浏览器兼容性*/
        /* -moz-box-shadow: 5px 120px 85px rgba(0, 0, 0, 0.7);
                    -webkit-box-shadow: 5px 120px 85px rgba(0, 0, 0, 0.7); */
      }
    }
  }
}
</style>